














import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class FilterToggle extends Vue {
  @Prop({type: Boolean, default: false}) value!: boolean

  get input() {
    return this.value
  }
  set input(val) {
    this.$emit('input', val)
  }

  get btnClass() {
    return {
      'filter-toggle__btn--active': this.input,
    }
  }

  async toggle() {
    this.input = !this.input
  }
}
