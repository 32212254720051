






















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {PlatformType} from '@/model/resource/PlatformType'
import {DappConnection} from '@/model/resource/DappConnection'

@Component
export default class FilterWalletFiltered extends Vue {
  @Prop({type: Object, required: true}) collection!: WalletCollection
  @Prop({type: String}) showOnly!: string

  get hasFilteredItems() {
    return (
      this.blockchainVersions?.length ||
      this.platformTypes?.length ||
      this.dappConnections?.length
    )
  }

  get blockchainVersions() {
    return this.collection.walletBlockchainVersion
  }

  get platformTypes() {
    if (!this.showOnly || this.showOnly === 'walletPlatformType') {
      return this.collection.walletPlatformType
    }
    return []
  }

  get dappConnections() {
    if (!this.showOnly || this.showOnly === 'walletDappConnection') {
      return this.collection.walletDappConnection
    }
    return []
  }

  @Emit('filter')
  clearBlockchainVersion(blockchainVersion: BlockchainVersion) {
    const {walletBlockchainVersion} = this.collection
    this.collection.walletBlockchainVersion =
      walletBlockchainVersion?.filter(
        item => item.$id !== blockchainVersion.$id
      ) || []
  }

  @Emit('filter')
  clearPlatformType(platformType: PlatformType) {
    const {walletPlatformType} = this.collection
    this.collection.walletPlatformType =
      walletPlatformType?.filter(item => item.$id !== platformType.$id) || []
  }

  @Emit('filter')
  clearDappConnection(dappConnection: DappConnection) {
    const {walletDappConnection} = this.collection
    this.collection.walletDappConnection =
      walletDappConnection?.filter(item => item.$id !== dappConnection.$id) ||
      []
  }

  @Emit('filter')
  doClear() {
    this.collection.walletBlockchainVersion = []
    this.collection.walletPlatformType = []
    this.collection.walletDappConnection = []
  }
}
