





























































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterWallet from '@/components/filters/FilterWallet.vue'
import {Wallet} from '@/model/resource/Wallet'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {ListWalletSchema} from '@/schema/resource/Wallet/ListWalletSchema'
import {ExportWalletSchema} from '@/schema/resource/Wallet/ExportWalletSchema'

@Component({
  components: {FilterToggle, FilterWallet},
})
export default class ListWalletView extends Mixins(MixinRouteMatch) {
  schema = new ListWalletSchema()
  collection = new WalletCollection()
  filterOpen = false

  async created() {
    this.collection.setOrderBy('order')
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  goToPersistView(item: Wallet) {
    this.$nav.pushByName('editWallet', item.$id)
  }

  async removeItem(item: Wallet) {
    await this.$dialog.remove(item)
    await item.removeWallet()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new WalletCollection().clearFilters().addFilter(params)

    await coll.listExportWallet()
    this.$xlsx.downloadFromSchema(coll.items, new ExportWalletSchema())
  }

  async changeOrder(item: Wallet, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
