import {Component, Vue} from 'vue-property-decorator'
import {classToPlain} from 'class-transformer'
import _ from 'lodash'

@Component
export class MixinRouteMatch extends Vue {
  get hasQueryParams() {
    return Object.keys(this.$route.query).length
  }

  updateRouteFromObject(value: any, extra: any = {}) {
    const oldQuery = this.$route.query
    const newQuery: any = {
      ...this.$route.query,
      ...classToPlain(value),
      ...extra,
    }
    this.prepareToSerialize(newQuery)

    // Convert numbers and booleans to string to compare
    const stringifiedNewQuery = _.mapValues(newQuery, val => {
      if (typeof val === 'number' || typeof val === 'boolean') {
        return String(val)
      }

      return val
    })

    // Compare if the query has changed
    if (_.isEqual(oldQuery, stringifiedNewQuery)) {
      return
    }

    this.$router.replace({query: newQuery})
  }

  updateObjectFromRoute(value: any) {
    const query = {...this.$route.query}
    this.prepareToUnserialize(query)
    Object.assign(value, query)
  }

  private prepareToSerialize(obj: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] == null || obj[key] === '') {
        delete obj[key]
      }
    })
  }

  private prepareToUnserialize(obj: any) {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string' && !obj[key].length) {
        obj[key] = null
      } else if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
        obj[key] = Number(obj[key])
      } else if (obj[key] === 'true') {
        obj[key] = true
      } else if (obj[key] === 'false') {
        obj[key] = false
      }
    })
  }
}
