























import {Component, Prop, Vue} from 'vue-property-decorator'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {FilterWalletSchema} from '@/schema/resource/Wallet/FilterWalletSchema'
import FilterWalletFiltered from '@/components/filters/FilterWalletFiltered.vue'

@Component({
  components: {
    FilterWalletFiltered,
  },
})
export default class FilterWallet extends Vue {
  @Prop({type: Object, required: true}) collection!: WalletCollection

  schema = new FilterWalletSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionDappConnection.queryAsPage(),
      this.collection.resource.collectionPlatformType.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryToFirstPage())
  }

  doClear() {
    this.collection.walletBlockchainVersion = []
    this.collection.walletPlatformType = []
    this.collection.walletDappConnection = []
    this.doFilter()
  }
}
